@media screen and (max-width: 1100px){
#wraper{     
    .main-sectors{                           
        .contentProceso{         
            .bloques{              
                .listado{                  
                    .linea{                       
                        .loguillo{
                            width: 100%;
                            max-width: 20%;/*---*/
                        }      
                        .contentVariaciones{width: 100%;}/*---*/
                        .variaciones{
                             max-width: 75%;             
                            .bloquecampos{
                                width: 100%;
                                max-width:49%; /*---*/                                                           
                                font-size: get-vw-tablet(17px);
                                .btn{
                                    font-size: get-vw-tablet(20px);                                
                                    padding: get-vw-tablet(5px)  get-vw-tablet(10px);                                 
                                     width: get-vw-tablet(160px);/*---*/
                                }
                                input{                                   
                                    height: get-vw-tablet(60px);                                 
                                    font-size: get-vw-tablet(19px); /*---*/                                
                                }
                                select{
                                    
                                    height: get-vw-tablet(65px);                                   
                                    font-size: get-vw-tablet(19px);  /*---*/                                 
                                }
                                label{margin-right:get-vw-tablet(5px); }/*---*/
                                .coste{
                                    font-size: get-vw-tablet(22px); /*---*/                                  
                                    span{font-size:get-vw-tablet(15px);}
                                }
                            }
                        }
                        .variacion1{padding: get-vw-tablet(15px) get-vw-tablet(15px) get-vw-tablet(0px) get-vw-tablet(15px);}
                        .variacion2{padding: get-vw-tablet(10px) get-vw-tablet(15px) get-vw-tablet(15px) get-vw-tablet(15px);}
                    }

                }
            }
            .bloque1{
            }
        }
    }
    .max1200{max-width: 90%!important;}
    .max80{max-width: 80%!important;}
}

#wraper{
    .main-sectors{      
        .contentHome{               
            .bloquecitos{
                max-width: 25%;    /*---*/         
            }
            
        }
    }
}

}