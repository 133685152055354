@media screen and (max-width: 1750px){
#main-header{  
    margin-bottom: get-vw-pc(30px); 
    .content-header{
             
        width: 100%;
        height: get-vw-pc(100px); 
        max-width: 90%; 
        nav{        
            width: 100%;
            max-width: 75%;
            ul{
                li{
                   
                    padding-right: get-vw-pc(40px);;
                    a{font-size:get-vw-pc(22px);
                      &:hover{color:$rojo;}
                    }
                    .active{font-weight: 800;}
                }
            }
        }
        .headerDerecho{         
            width: 100%;
            max-width: 25%;
            ul{              
                li{                               
                    a{font-size:get-vw-pc(22px);                     
                      padding: 7px 10px;
                      border-radius: 5px;
                      background: #fff;;
                      &:hover{color:$rojo;}
                    }
                    &:last-child{padding-left: get-vw-pc(40px);;}
                    .carrito1{                     
                        margin-top: get-vw-pc(16px);
                    }
                    .carrito2{
                        font-size: get-vw-pc(14px);                      
                        margin-top: get-vw-pc(-10px);
                    }
                }
            }

        }
                    .contentLogin{               
                top:get-vw-pc(100px);             
                .inicioLogin{
                        width: 100%;
                        max-width: get-vw-pc(480px);   
                        margin-top: 0%;                       
                        .contentFormLogin{                         
                            padding: 8% 10% 10% 10%;
                            width: 80%;                            
                            h5{
                                font-size:get-vw-pc(26px);   
                                line-height: get-vw-pc(35px);                                 
                                padding-left: 0px;
                            }
                            .campo{                              
                                margin-bottom: get-vw-pc(20px);
                                width: 100%;                                
                                input{
                                    width: 90%;
                                    padding: 5%;                                   
                                    font-size: get-vw-pc(22px);                                  
                                }
                            }
                            .item{width: 100%;font-size:get-vw-pc(18px); }
                            .btn{
                                    font-size: get-vw-pc(22px);                                  
                                    padding: 10px 0px 10px 0px;                                   
                                    border-radius: 30px;                                   
                                    width: 100%;
                                    margin-top: get-vw-pc(20px);
                                     
                                }
                                .olvido{                                                                         
                                       font-size:get-vw-pc(18px);                                      
                                       margin-right: 5%;
                                       margin-top:get-vw-pc(22px);  
                                }
                        }
                        .divisionLogin{                          
                            padding: 5%;
                            a{font-size:get-vw-pc(18px);}
                            .semicentrado{margin-right: 10%;margin-bottom: 0%;}
                        }
                    }
            }
            .contentLogueado{
                position: absolute;
                top:get-vw-pc(100px);                   
                .inicioLogin{                  
                        width: 100%;
                        min-width: get-vw-pc(230px);   
                        margin-top: 0%;                      
                        .contentFormLogin{                          
                            padding: 8% 10% 10% 10%;
                            width: 80%;                                                       
                            a{                               
                                margin-bottom: get-vw-pc(10px);
                                font-size: get-vw-pc(18px);                               
                                &:last-child{margin-bottom: 0px;}
                            }
                        }
                        .divisionLogin{                          
                            padding: 5%;
                            a{font-size:get-vw-pc(18px);}
                            .semicentrado{display: inline-block;margin-right: 10%;margin-bottom: 0%;}
                        }
                    }
            }
    }
}
}
