@media screen and (max-width: 900px){
footer{ 
        font-size: get-vw-mobile(20px); 
        margin-top: get-vw-mobile(50px); 
        max-width: 90%;        
    .content{     
      .columna{         
          margin-right: get-vw-mobile(40px); ;        
          .items{
              margin-bottom: get-vw-mobile(10px);
            b{              
                margin-bottom: get-vw-mobile(20px); 
            }
        }
      }
        
    }
    
}
}