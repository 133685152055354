#main-header{
    display: block;
    width: 100%;
    -webkit-box-shadow: 0px 10px 10px -8px rgba(112, 112, 112, 0.15);
    -moz-box-shadow: 0px 10px 10px -8px rgba(112, 112, 112, 0.15);
    box-shadow: 0px 10px 10px -8px
    rgba(112, 112, 112, 0.15);  
    margin-bottom: get-vw(30px); 
    .btnMenu,.carrito3{
        display: none;
    }
    .content-header{
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        position: relative;
        width: 100%;
        height: get-vw(100px); 
        max-width: get-vw(1680px); 
        nav{
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            max-width: 75%;
            ul{
                li{
                    display: inline-block;
                    padding-right: get-vw(40px);
                    a{
					  font-size:get-vw(22px);
					  vertical-align: bottom;
					  line-height: 32px;
                      &:hover{color:$rojo;}                      
                    }
					img{
						width:get-vw(170px); 
						vertical-align: middle;
					}
                    .active{font-weight: 800;}
					
                }
            }
        }
        .headerDerecho{
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-end;
            width: 100%;
            position: relative;
            max-width: 25%;
            ul{
                text-align: right;
                li{
                    display: inline-block;                    
                    a{
                      font-size:get-vw(22px); 
                      display: block;
                      padding: 7px 10px;
                      border-radius: 5px;
                      background: #fff;;
                      &:hover{color:$rojo;}
                      
                    }
                    &:last-child{padding-left: get-vw(40px);;}
                    .carrito1{
                        display: block;
                        margin-top: get-vw(16px);
                    }
                    .carrito2{
                        font-size: get-vw(14px);
                        color:$rojo;
                        float: left;
                        margin-top: get-vw(-10px);
                    }
                }
                .active{
                    a{
                        background: $grisclaro;
                        
                    }
                    
                    
                }
            }
            
            
        }
        .contentLogin{
                position: absolute;
                top:get-vw(80px);
                z-index: 999;
                right: 0px;
                display: none;
                background: #fff;                
                .inicioLogin{
                        width: 100%;
                        max-width: get-vw(480px);   
                        margin-top: 5%;
                        border:1px solid $grisCCC;
                        -webkit-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            -moz-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                        .contentFormLogin{
                            display: inline-block;
                            padding: 8% 10% 10% 10%;
                            width: 80%;                            
                            h5{
                                font-size:get-vw(26px);   
                                line-height: get-vw(35px);  
                                color:$azul;
                                font-family: $helveticaBd;  
                                padding-left: 0px;
                            }
                            .campo{
                                display: inline-block;
                                margin-bottom: get-vw(20px);
                                width: 100%;                                
                                input{
                                    width: 90%;
                                    padding: 5%;
                                    border:1px solid #CCCCCC;
                                    border-radius: 3px;
                                    font-size: get-vw(22px);
                                    color:$grisoscuro;
                                    background: $grisclaro;
                                }
                            }
                            .item{display: inline-block;width: 100%;font-size:get-vw(18px);color:$grisoscuro; }
                            .btn{
                                    font-size: get-vw(22px);
                                    float:left;
                                    text-align: center;
									padding: 10px 0px;
									background: linear-gradient($morado1, $morado2);
									border-radius: 30px;
                                    color:#fff;
                                    font-family: $helveticaBd; 
                                    width: 100%;
                                    margin-top: get-vw(20px);
                                     
                                }
                                .olvido{
                                   
                                       font-family: $helveticaBd;
                                       font-size:get-vw(18px);
                                       color:$grisoscuro;
                                       text-decoration: underline;
                                       float: right;
                                       margin-right: 5%;
                                       margin-top:get-vw(22px);  
                                }
                        }
                        .divisionLogin{
                            border-top:1px solid $grisCCC;
                            text-align: right;
                            padding: 5%;
                            a{font-size:get-vw(18px);color:$gris;}
                            .semicentrado{display: inline-block;margin-right: 10%;margin-bottom: 0%;}
                        }
                    }
            }
            .contentLogueado{
                position: absolute;
                top:get-vw(90px);
                display: none;
                right: 0px;
                z-index: 999;
                background: #fff;                
                .inicioLogin{
                    position: relative;
                        width: 100%;
                        min-width: get-vw(230px);   
                        margin-top: 5%;
                        border:1px solid $grisCCC;
                        -webkit-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            -moz-box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                            box-shadow: 2px 2px 5px 0px rgba(112,112,112,1);
                        .contentFormLogin{
                            display: inline-block;
                            padding: 8% 10% 10% 10%;
                            width: 80%;                                                       
                            a{
                                display: block;
                                color:$gris;
                                margin-bottom: get-vw(10px);
                                font-size: get-vw(18px);
                                b{
                                     font-family: $helveticaBd;  
                                     color:$grisoscuro;
                                }
                                &:last-child{margin-bottom: 0px;}
                            }
                        }
                        .divisionLogin{
                            border-top:1px solid $grisCCC;
                            text-align: right;
                            padding: 5%;
                            a{font-size:get-vw(18px);color:$gris;}
                            .semicentrado{display: inline-block;margin-right: 10%;margin-bottom: 0%;}
                        }
                    }
            }
        
    }
    
    .contentmenumvl{
                position: absolute;
                left: 0;
                top:get-vw(60px);
                /*display: none;*/
                z-index: 999;  
                width: 100%;
                max-width: 100%;   
                text-align: center;
                display: none;
                background: #fff;                
                .menumvl{
                    position: relative;
                         width: 100%;
                max-width: 100%; 
                        display: inline-block;
                        border-top: 0px;
                       -webkit-box-shadow: 0px 2px 5px 0px rgba(112,112,112,1);
                            -moz-box-shadow: 0px 2px 5px 0px rgba(112,112,112,1);
                            box-shadow: 0px 2px 5px 0px rgba(112,112,112,1);
                            ul{
                                li{
                                    display: inline-block;
                                    width: 100%;
                                    text-align: center;
                                    a{
                                       display: block;
                                       width: 100%; 
                                       padding-top: get-vw(10px);
                                       padding-bottom: get-vw(10px);
                                    }
                                    
                                }
                            }
                    }
            }
}
