@media screen and (max-width: 1100px){
#main-header{     
    .content-header{                            
            .contentLogin{               
                top:get-vw-tablet(100px);                            
            }
            .contentLogueado{
                top:get-vw-tablet(100px);                      
                .inicioLogin{                  
                        width: 100%;
                        min-width: get-vw-tablet(230px);   
                        margin-top: 0%;                      
                        .contentFormLogin{                          
                            padding: 8% 10% 10% 10%;
                            width: 80%;                                                       
                            a{                               
                                margin-bottom: get-vw-tablet(10px);
                                font-size: get-vw-tablet(18px);                               
                                &:last-child{margin-bottom: 0px;}
                            }
                        }
                        .divisionLogin{                          
                            padding: 5%;
                            a{font-size:get-vw-tablet(18px);}
                            .semicentrado{display: inline-block;margin-right: 10%;margin-bottom: 0%;}
                        }
                    }
            }
        }
    }
}

