footer{
     display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: top;
        width: 100%;
        text-align: left;
        font-size: get-vw(20px); 
        margin-top: get-vw(50px); 
        max-width: get-vw(1200px); 
       
    .content{
        vertical-align: top;
      display: inline-block;
      .columna{
          vertical-align: top;
          margin-right: get-vw(40px); ;
          display: inline-block;
          .items{
              margin-bottom: get-vw(10px);
            a{color:$gris; }
            b{
                font-family: $helveticaBd;
                margin-bottom: get-vw(20px); 
                float:left;
            }
        }
      }
        
    }
    
}