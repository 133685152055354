@font-face {
  font-family: 'HelveticaNeueLTStd-It';
  src: url('fonts/HelveticaNeueLTStd-It.eot?#iefix') format('embedded-opentype'),  url('fonts/HelveticaNeueLTStd-It.otf')  format('opentype'),
	     url('fonts/HelveticaNeueLTStd-It.woff') format('woff'), url('fonts/HelveticaNeueLTStd-It.ttf')  format('truetype'), url('fonts/HelveticaNeueLTStd-It.svg#HelveticaNeueLTStd-It') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeueLTStd-Bd';
  src: url('fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),  url('fonts/HelveticaNeueLTStd-Bd.otf')  format('opentype'),
	     url('fonts/HelveticaNeueLTStd-Bd.woff') format('woff'), url('fonts/HelveticaNeueLTStd-Bd.ttf')  format('truetype'), url('fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-BdIt';
  src: url('fonts/HelveticaNeueLTStd-BdIt.eot?#iefix') format('embedded-opentype'),  url('fonts/HelveticaNeueLTStd-BdIt.otf')  format('opentype'),
	     url('fonts/HelveticaNeueLTStd-BdIt.woff') format('woff'), url('fonts/HelveticaNeueLTStd-BdIt.ttf')  format('truetype'), url('fonts/HelveticaNeueLTStd-BdIt.svg#HelveticaNeueLTStd-BdIt') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTStd-Roman';
  src: url('fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),  url('fonts/HelveticaNeueLTStd-Roman.otf')  format('opentype'),
	     url('fonts/HelveticaNeueLTStd-Roman.woff') format('woff'), url('fonts/HelveticaNeueLTStd-Roman.ttf')  format('truetype'), url('fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'FontAwesome';
  src: url('fonts/fontawesome-webfont.eot');
  src: url('fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/fontawesome-webfont.woff2') format('woff2'), url('fonts/fontawesome-webfont.woff') format('woff'), url('fonts/fontawesome-webfont.ttf') format('truetype'), url('fonts/fontawesome-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

$iconos:'FontAwesome';

$helveticaIt:'HelveticaNeueLTStd-It';
$helveticaBd:'HelveticaNeueLTStd-Bd';
$helveticaRoman:'HelveticaNeueLTStd-Roman';
$helveticaBdIt:'HelveticaNeueLTStd-BdIt';