@media screen and (max-width: 900px){
#wraper{  
    width: 100%;
    .main-sectors{      
        width: 100%;
        max-width: 90%;       
        .content{          
            width: 100%;        
            margin-bottom: get-vw-mobile(40px);      
            .items{
                width: 100%;
                font-size: get-vw-mobile(23px); 
                line-height: get-vw-mobile(26px); 
                margin-bottom: get-vw-mobile(20px); 
                max-width: 100%;                
                h2{
                    font-size: get-vw-mobile(50px);
                    line-height: get-vw-mobile(55px);                 
                    max-width: get-vw-mobile(683px);
                }
                p{
                    max-width: get-vw-mobile(683px);
                    font-size:get-vw-mobile(22px);
                    line-height: get-vw-mobile(35px);
                }
                .btn{                 
                    padding: get-vw-mobile(10px) get-vw-mobile(20px);                                               
                    margin-top: get-vw-mobile(30px);
                    margin-bottom: get-vw-mobile(30px);
                }
                .contenidoFondo{
                    position: absolute;
                    bottom: 0;
                    right: get-vw-mobile(0px);
                    margin-right: 0%;                    
                    max-width: 90%;
                    p{
                        margin-bottom:  get-vw-mobile(-50px);
                        font-size: get-vw-mobile(18px);
                        line-height: get-vw-mobile(25px);
                    }
                }
            }
        }       
        .contentProceso{
            /*display:inline-flex;*/
            display: inline-block;           
            width:10000px;
            margin: auto;
            align-items: flex-start;
            /*overflow-x: auto;*/
            position: relative;
            justify-content: flex-start;
            .bloques{              
                width: 100%;
                max-width: 99%!important;  
                .headerProceso{                   
                    width: 100%;
                    height: get-vw-mobile(100px);                    
                    h3{
                        font-size: get-vw-mobile(30px);  
                        line-height: get-vw-mobile(35px);                               
                    }
                }
                .provincias{                  
                    margin-top: get-vw-mobile(40px);
                    margin-bottom: get-vw-mobile(20px);
                    width: 100%;
                    select{
                        width: 100%;
                        height: get-vw-mobile(65px);                       
                        font-size: get-vw-mobile(22px);
                    }
                }
                h4{color:$grisoscuro;
                   font-size:get-vw-mobile(26px);
                   margin-bottom: get-vw-mobile(10px);
                   margin-top: get-vw-mobile(10px);
				   .parentesis{
				    font-size:get-vw-mobile(20px);
				   }	
				   				   
                }
                h5{font-size: get-vw-mobile(22px);padding: get-vw-mobile(20px);width: 100%;}
                .listado{                  
                    width: 100%;
                    .linea{                       
                        width: 100%;                      
                        .esquinaverde{
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 9;
                        }
                        .loguillo{
                            width: 90%;
                            max-width: get-vw-mobile(183px);
                            
                        }                        
                        .variaciones{
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;                        
                            .bloquecampos{
                                width: 100%;
                                margin-top: get-vw-mobile(10px);
                                max-width:98%;                                                            
                                font-size: get-vw-mobile(18px);
                                .btn{
                                    font-size: get-vw-mobile(22px);                                
                                    padding:get-vw-mobile(10px) get-vw-mobile(20px);                                     
                                    width: get-vw-mobile(180px);
                                }
                                input{
                                    width: 100%;
                                    height: get-vw-mobile(60px);                                 
                                    font-size: get-vw-mobile(22px);                                 
                                }
                                span{width: 100%;}
                                select{
                                    width: 100%;;
                                    height: get-vw-mobile(65px);                                   
                                    font-size: get-vw-mobile(22px);                                   
                                }
                                label{margin-right:get-vw-mobile(10px); width: 20%;}
                                .coste{
                                    font-size: get-vw-mobile(26px);                                   
                                    span{font-size:get-vw-mobile(15px);}
                                }
                            }
                        }
                        form{display: inline-block;width: 100%;}
                        .variacion1{padding: get-vw-mobile(15px) get-vw-mobile(15px) get-vw-mobile(0px) get-vw-mobile(15px);max-width: 70%;}
                        .variacion2{padding: get-vw-mobile(10px) get-vw-mobile(15px) get-vw-mobile(15px) get-vw-mobile(15px);max-width: 69%;}
                    }
                    .activo{
                        /*background:url(../img/esquinaverde.png)  no-repeat top right;*/
                        max-width: 99.9%;                      
                    }
                }
                .contentPartes{                   
                    width: 98%;  
                    flex-wrap: wrap;
                    padding: 2%;                   
                    .partes{   
                        width: 96%;
                        .campo{
                            input{
                              width: 100%;    
                              height: get-vw-mobile(40px);                                
                                    font-size: get-vw-mobile(22px);                                 
                                    padding: get-vw-mobile(10px);
                            }                            
                            textarea{
                                   width: 100%;                                      
                                    font-size: get-vw-mobile(22px);                                  
                                    padding: get-vw-mobile(10px);
                            }                            
                        }
                        .alto{
                            margin-top:  get-vw-mobile(20px);
                            margin-bottom: get-vw-mobile(20px);
                                textarea{
                                    height: get-vw-mobile(132px);
                                }
                            }
                        .medio{
                            
                            margin-bottom: get-vw-mobile(20px);
                            textarea{
                                height: get-vw-mobile(75px);
                            }
                        }
                        .botones{
                            width: 100%;                         
                            margin-bottom: get-vw-mobile(20px);                          
                            .btn{
                                font-size: get-vw-mobile(22px);                               
                                padding: get-vw-mobile(10px) get-vw-mobile(20px);                                                 
                                width: get-vw-mobile(293px);
                            }
                            .campologo{
                                width: get-vw-mobile(290px);
                                height: get-vw-mobile(40px);                             
                                font-size: get-vw-mobile(22px);                               
                                padding: get-vw-mobile(10px)
                            }
                            .formatos{
                                p{
                                    font-size:get-vw-mobile(12px);
                                    line-height: get-vw-mobile(15px);padding-left: get-vw-mobile(20px);
                                }
                            }
                        }
                        &:last-child{
                            margin-left: get-vw-mobile(0px);
                            margin-bottom: get-vw-mobile(40px);
                        }
                        .contentModelo{                         
                            width: 100%;                          
                            width: get-vw-mobile(525px);
                            .modelo{                              
                                .bloqImage{min-width:get-vw-mobile(260px); }
                                .bloqInfo{                                   
                                    padding: get-vw-mobile(10px);
                                     color:#000;
                                    h6{
                                       font-size: get-vw-mobile(30px);                                                            
                                       line-height: get-vw-mobile(34px);                                     
                                       margin-bottom: get-vw-mobile(15px);
                                    }
                                    p{
                                       font-size: get-vw-mobile(15px); 
                                       line-height: get-vw-mobile(22px);
                                        margin-bottom: get-vw-mobile(15px);
                                    }
                                }
                            }
                            .notita{
                                color:#000;font-size: get-vw-mobile(13px); 
                                       line-height: get-vw-mobile(16px);
                                       padding-left:  get-vw-mobile(10px);
                            }
                            .by{ color:#000;font-size: get-vw-mobile(12px); 
                                       line-height: get-vw-mobile(16px);padding-right: get-vw-mobile(10px);margin-bottom:get-vw-mobile(10px); }
                        }
                        .contentCheckbox{                         
                            font-size: get-vw-mobile(18px);
                        }
                    }
                    .parte2{
                        order:-1;
                        .btn{
                                    font-size: get-vw-mobile(22px);                                   
                                    padding: get-vw-mobile(10px) get-vw-mobile(20px);                               
                                     width: get-vw-mobile(230px);
                                     margin-top: get-vw-mobile(20px);
                                }
                    }
                }
                .nota{
                     font-size: get-vw-mobile(18px);                   
                     padding: get-vw-mobile(20px);                   
                     width: 100%;
                }
            }
            .bloque1{

            }
            .bloque2{
                max-width: get-vw-mobile(800px);
                .headerProceso{
                    padding-right: 2%;
                    h3{                        
                        padding-left:  get-vw-mobile(20px);
                    }
                }
            }
            .bloque3{               
                .headerProceso{
                    padding-right: 2%;
                    padding-left:  get-vw-mobile(20px);                   
                }
                .contentLogin{                   
                    width: 100%;
                    height: 100%;
                    .inicioLogin{
                        width: 100%;
                        max-width: get-vw-mobile(480px);   
                        margin-top: 5%;                      
                        .contentFormLogin{                         
                            padding: 2% 5% 10% 5%;
                            width: 80%;                            
                            h5{
                                font-size:get-vw-mobile(26px);   
                                line-height: get-vw-mobile(35px);                               
                                padding-left: 0px;
                            }
                            .campo{                              
                                margin-bottom: get-vw-mobile(20px);
                                width: 100%;                                
                                input{
                                    width: 90%;
                                    padding: 5%;                                  
                                    font-size: get-vw-mobile(22px);                                
                                }
                            }
                            .item{width: 100%;font-size:get-vw-mobile(18px); }
                            .btn{
                                    font-size: get-vw-mobile(22px);                                 
                                    padding: get-vw-mobile(10px) 0px get-vw-mobile(10px) 0px;                                                                 
                                    width: 100%;
                                    margin-top: get-vw-mobile(20px);
                                     
                                }
                                .olvido{                                   
                                       font-size:get-vw-mobile(18px);                                     
                                       float: right;
                                       margin-right: 5%;
                                       margin-top:get-vw-mobile(22px);  
                                }
                        }
                        .divisionLogin{                         
                            padding: 5%;
                            a{font-size:get-vw-mobile(18px);}
                            .semicentrado{float: right;margin-right: 10%;margin-bottom: 5%;}
                        }
                    }
                }
            }
            .bloque4{
                .inicioLogin{
                    width: 100%;
                    max-width: get-vw-mobile(805px)!important;  
                    .contentFormLogin{                  
                        .campo{max-width: 98%;
                               &:nth-child(2n+1){margin-left: 0%;}
                        }
                        .checkfactura{margin-bottom:get-vw-mobile(20px)}
                    }
                }
            }
            .bloque5{
                max-width:get-vw-mobile(800px);  
                .headerProceso{
                    padding-right: 2%;
                    padding-left:  get-vw-mobile(20px);                   
                }
                .contentPedido{                   
                    padding: get-vw-mobile(20px);     
                    flex-wrap: wrap;
                    h4{font-size: get-vw-mobile(26px);}
                    .pedido{
                         margin-right: 0%;
                         width: 100%;
                        max-width:100%;  
                        h4{
                            width: 100%;                         
                            padding-bottom: get-vw-mobile(20px);  
                        }
                        .lineas{                         
                            width: 100%;                          
                            margin-top: get-vw-mobile(20px);
                            .item{
                                
                                p{
                                    line-height: get-vw-mobile(30px);  
                                    font-size: get-vw-mobile(22px);                                     
                                    span{}
                                }
                            }
                            .item1{                             
                                max-width:get-vw-mobile(155px);  
                            }
                            .item2{                              
                                max-width:get-vw-mobile(309px);  
                                margin-left: get-vw-mobile(10px);  
                            }
                            .item3{                              
                                 max-width:get-vw-mobile(70px);   
                            }
                            .item4{       
                                font-size: get-vw-mobile(20px); 
                                 max-width:get-vw-mobile(90px);   
                            }
                            .item5{                               
                               max-width:get-vw-mobile(130px);   
                               font-size: get-vw-mobile(19px); 
                            }
                            .item6{                              
                               max-width:get-vw-mobile(90px);  
                               a{font-size: get-vw-mobile(20px); }
                            }
                            
                            .infototales{ 
                                         p{
                                             font-size: get-vw-mobile(20px); 
                                         }
                            }
                            .totales{
                                     p{
                                         font-size: get-vw-mobile(20px);
                                     }
                            }
                            .total{font-size: get-vw-mobile(30px);}
                            .preciofinal{font-size: get-vw-mobile(30px);}
                        }
                        .subtotales{padding-top: get-vw-mobile(10px);}
                    }
                    .datos{                       
                        max-width:100%;
                        p{
                          font-size: get-vw-mobile(22px);   
                          line-height: get-vw-mobile(35px);   
                        }
                        .metodo{                            
                                margin-top: get-vw-mobile(50px);   
                            
                        }
                        .minitexto{
                            font-size: get-vw-mobile(18px);   
                          line-height: get-vw-mobile(24px);  
                          
                        }
                        img{margin-top: get-vw-mobile(30px);  }
                        .btn{
                            margin-top: get-vw-mobile(40px);
                            margin-bottom: get-vw-mobile(40px);
                                    font-size: get-vw-mobile(22px);                                  
                                    padding:get-vw-mobile(10px) get-vw-mobile(20px);                                  
                                    width: get-vw-mobile(185px);                                    
                                }
                                .seguircomprando{
                                   font-size: get-vw-mobile(18px);
                                   margin-top: get-vw-mobile(0px);
                                }
                    }
                }
            }
        }
    }
}

#wraper{
    .main-sectors{      
        .contentHome{
            display:inline-flex;          
            width: 100%;          
            margin-bottom: get-vw-mobile(40px);                 
            .bloquecitos{
                max-width: 30%;             
                img{
                    margin-bottom: get-vw-mobile(40px);      
                }
                h6{
                     font-size: get-vw-mobile(30px); 
                    line-height: get-vw-mobile(35px);                   
                     margin-bottom: get-vw-mobile(20px);
                }
                p{font-size: get-vw-mobile(22px);  }
                margin-bottom: get-vw-mobile(40px); 
            }
            
            
        }
        .contenedorSlide{
            display:inline-flex;
            text-align: left;;
            position:relative;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin: auto;
            align-items: center;
            margin-bottom: get-vw-mobile(40px);      
            justify-content: space-between;
            .cycle-slideshow{
            max-width: 229px;   
            .bloquecitos{
                max-width: 100%;
                text-align: center;
                img{
                    margin-bottom: get-vw-mobile(40px);      
                }
                h6{
                     font-size: get-vw-mobile(30px); 
                    line-height: get-vw-mobile(35px);  
                    font-family: $helveticaBd; 
                    color:$azul;
                     margin-bottom: get-vw-mobile(20px);
                }
                p{font-size: get-vw-mobile(22px);  }
                margin-bottom: get-vw-mobile(40px); 
            }
            
        }
        .center{cursor: pointer;}
        }
        
        .btnComenzar{
            margin-bottom: get-vw-mobile(30px);           
            .btn{
                margin-top: get-vw-mobile(40px);
                margin-bottom: get-vw-mobile(40px);
                font-size: get-vw-mobile(22px);              
                width: get-vw-mobile(195px);
            }
        }
        .titular{
                h3{
                    font-size: get-vw-mobile(30px); 
                    line-height: get-vw-mobile(35px);                    
                }
                p{font-size: get-vw-mobile(22px);  }
                margin-bottom: get-vw-mobile(40px);   
            }
    }
}

#wraper{
    .main-sectors-ancho-completo{
        padding-top: get-vw-mobile(50px); 
        padding-bottom: get-vw-mobile(50px); 
        margin-bottom: get-vw-mobile(50px);  
         .titular{
             display: inline-block;
                p{
                    display: inline-block;
                    font-size: get-vw-mobile(30px); 
                    line-height: get-vw-mobile(35px);  
                }
 
            }
    }
}
.contacto{
    margin-top: get-vw-mobile(50px);
    max-width:get-vw-mobile(1200px)!important;     
            width: 100%;        
            margin-bottom: get-vw-mobile(40px);      
            .titular{
                margin-top: get-vw-mobile(40px);      
                width: 100%;
                h3{
                    font-size: get-vw-mobile(30px); 
                    line-height: get-vw-mobile(35px);  
                    margin-bottom: get-vw-mobile(20px);  
                }
                p{font-size: get-vw-mobile(22px);  }
                margin-bottom: get-vw-mobile(40px);   
            }
            .content{
                width: 100%;
                                max-width:100%;                                                       
                                font-size: get-vw-mobile(18px);
                        .campo{
                            margin-bottom: get-vw-mobile(20px);
                                 width: 100%;
                                .btn{
                                    font-size: get-vw-mobile(22px);
                                    padding: get-vw-mobile(10px) get-vw-mobile(20px);                                 
                                     width: get-vw-mobile(180px);
                                     
                                }
                                input{
                                    width: 96%;
                                    height: get-vw-mobile(60px);                                   
                                    font-size: get-vw-mobile(22px);                                 
                                    padding-left: 2%;
                                }
                                textarea{
                                    width: 97%;
                                    height: get-vw-mobile(300px);                                  
                                    font-size: get-vw-mobile(22px);                                   
                                    padding-left: 2%;
                                    padding-top: 2%;                                   
                                }
                                select{
                                    width: 99.3%;
                                    height: get-vw-mobile(65px);
                                    font-size: get-vw-mobile(22px);                                                                                                                                          
                                }

                            }
            }
}
.preguntas{
    margin-top: get-vw-mobile(50px);
    max-width:get-vw-mobile(1200px)!important;     
            width: 100%;           
            margin-bottom: get-vw-mobile(40px);      
     .titular{
                margin-top: get-vw-mobile(40px);      
                width: 100%;
                h3{
                    font-size: get-vw-mobile(30px); 
                    line-height: get-vw-mobile(35px);                    
                    margin-bottom: get-vw-mobile(40px);  
                }
                margin-bottom: get-vw-mobile(40px);   
            }
            #accordion{
                h3{
                    font-size: get-vw-mobile(26px);
                    line-height: get-vw-mobile(32px);                   
                    margin-bottom: get-vw-mobile(32px);                   
                }
                div{
                    font-size: get-vw-mobile(22px);
                }
                .ui-accordion-header-active{                   
                }
            }
}

#wraper{  
    width: 100%;
    .main-sectors{       
        width: 100%;
        max-width: 90%;    
        .contentCentro{           
            width: 100%;         
            .itemCentro{
                width: 100%;
                font-size: get-vw-mobile(23px); 
                line-height: get-vw-mobile(26px); 
                max-width: 48%; 
                margin-top: 5%;               
                h2{
                    font-size: get-vw-mobile(30px); 
                    line-height: get-vw-mobile(35px); 
                    margin-top: 3%;                 
                }
                p{
                    font-size: get-vw-mobile(22px); 
                    line-height: get-vw-mobile(35px); 
                }
                .btn{
                    font-size: get-vw-mobile(22px); 
                    padding: get-vw-mobile(10px) get-vw-mobile(10px);               
                    margin-top: get-vw-mobile(50px);                   
                    width: 100%;
                    max-width: get-vw-mobile(273px);
                }
            }
            .fallo{
                margin-top: 2%;
            }
        }
    }
}



#wraper2{ 
    width: 100%;
    .headerProceso{          
            width: 100%;
            margin-top: get-vw-mobile(-30px);  
            height: get-vw-mobile(100px);  
            h3{
                font-size: get-vw-mobile(30px);  
                line-height: get-vw-mobile(35px);                   
            }
            .bloquecentrado{
                 width: 100%;
                 max-width: 90%; 
            }
        }
    .main-sectorsCuenta{   
        width: 100%;
        max-width: get-vw-mobile(1920px);    
        
        .contentCentro{           
            width: 100%;        
            .itemCentro{
                width: 100%;
                max-width: get-vw-mobile(480px); 
                font-size: get-vw-mobile(23px); 
                line-height: get-vw-mobile(26px); 
                max-width: 48%; 
                margin-top: 3%;               
               .contentLogin{                  
                    width: 100%;
                    height: 100%;
                    .inicioLogin{
                        width: 100%;
                        max-width: get-vw-mobile(480px);   
                        margin-top: 2%;                      
                        .contentFormLogin{                           
                            padding: 2% 10% 10% 10%;
                            width: 80%;                            
                            h5{
                                font-size:get-vw-mobile(26px);   
                                line-height: get-vw-mobile(35px);                                 
                                padding-left: 0px;                              
                                padding-bottom: get-vw-mobile(15px);  
                                padding-top: get-vw-mobile(15px);  
                            }
                            .campo{                               
                                margin-bottom: get-vw-mobile(20px);
                                width: 100%;                                
                                input{
                                    width: 90%;
                                    padding: 5%;                                   
                                    font-size: get-vw-mobile(22px);                                  
                                }
                            }
                            .item{width: 100%;font-size:get-vw-mobile(18px); }
                            .btn{
                                    font-size: get-vw-mobile(22px);                                  
                                    padding: get-vw-mobile(10px)  0px get-vw-mobile(10px)  0px;                                 
                                     width: 100%;
                                     margin-top: get-vw-mobile(20px);                                     
                                }
                                .olvido{                                                                        
                                       font-size:get-vw-mobile(18px);                                     
                                       margin-right: 5%;
                                       margin-top:get-vw-mobile(22px);  
                                }
                        }
                        .divisionLogin{
                            padding: 5%;
                            a{font-size:get-vw-mobile(18px);}
                            .semicentrado{margin-right: 10%;margin-bottom: 5%;}
                        }
                        .textoeliminnar{                           
                            font-size: get-vw-mobile(22px);
                            margin-bottom: get-vw-mobile(20px);
                            line-height:  get-vw-mobile(35px);
                            b{
                            }
                        }
                    }
               }
            }
        }
        .bloqueexpandido{
            width: 100%;
            margin-top: get-vw-mobile(0px);  
            max-width: 90%;  
            a{
                font-size: get-vw-mobile(18px);  
                line-height: get-vw-mobile(30px); 
            }
        }
        .contentExpandido{
            display: inline-flex;          
            width: 100%;
            .bloque{               
                width: 100%;
                .lineas{                  
                    width: 100%;       
                    padding-bottom: get-vw-mobile(40px);
                    p{
                        line-height: get-vw-mobile(35px);
                        font-size: get-vw-mobile(22px);
                        span{
                        }
                        a{  float:left;  
                          sup{
                              float:right;                            
                              font-size: 10px;
                              line-height: 9px;
                              width: 10px;
                              height: 10px;                          
                                border-radius: 50%;
                                
                          }
                        }
                    }
                    .item2{margin-left:get-vw-mobile(20px);  }
                     .btn{
                                font-size: get-vw-mobile(22px);                              
                                padding: get-vw-mobile(10px) get-vw-mobile(10px);                             
                                border-radius: 3px;                               
                                width: 100%;
                                 width: get-vw-mobile(277px);
                                }
                                
                                
                }
                .activo{
                    background: url(../img/flechaverde.png)  no-repeat center right $grisclaro;;
                }
                .empresa{
                        p{                           
                            font-size: get-vw-mobile(22px);
                        }
                        .empresatitulo{                            
                            margin-bottom: get-vw-mobile(20px);
                        }
                        .fecha{font-size:get-vw-mobile(18px); }
                }
                .cliente{                       
                        p{                            
                            font-size: get-vw-mobile(22px);
                        }
                        .clientetitulo{                           
                            margin-bottom: get-vw-mobile(20px);
                        }
                        .fecha{font-size:get-vw-mobile(18px);}
                }
                .formulario{                    
                    .item{
                        width: 100%;
                        textarea{                          
                            width: 96%;                           
                            font-size: get-vw-mobile(22px);                           
                            padding: 2%;                           
                        }
                        .btnMsj{
                            width: 35px;
                            height: 35px;                          
                            background: url(../img/btonenviarmnsj.png)  no-repeat center center transparent;;                        
                        }
                    }
                }
                .cabecera{
                     padding-top: get-vw-mobile(40px);
                }
                .contenerdomaximo{
                    width: 100%;
                    max-width: 90%;;                 
                }
                .bloquecentrado{max-width: 90%;;}
                .headerProceso .abierto{max-width: get-vw-mobile(840px);;}
                
            }
            .bloque1{
                .contenerdomaximo{
                    .pedido{width: 100%;}
                    
                }
                .pedido{
                            width: 100%;
                            .lineas{
                                padding: get-vw-mobile(20px);;
                                margin-bottom: get-vw-mobile(20px);;
                                margin-left: get-vw-mobile(-20px);;
                            }
                        }
                .abierto{
                        .pedido{
                          max-width: get-vw-mobile(840px);;
                            .lineas{
                                 width: 90%;       
                    padding: 5%;
                            }
                        }
                    }
                
            }
            .bloque2{
                position: absolute;
                right: 0;
                max-width: 100%;
                text-align: left;
                 .contenerdomaximo{                   
                    .pedido{width: 100%;}
                    
                 }
                 .abierto{
                        .pedido{
                            width: 100%;max-width: get-vw-mobile(840px);;
                            .lineas{
                                 width: 90%;       
                    padding: 5%;
                            }
                        }
                    }
                    .headerProceso{
                        .item{
                           width: 100%;max-width: 89%;

                        }
                    }
            }
        }
    }
}



.contentLegales{
    h2{
        font-size: get-vw-mobile(30px);;
        margin-bottom: get-vw-mobile(60px);;
        margin-top:get-vw-pc(30px);; 
    }
    h3{
        font-size: get-vw-mobile(26px);;
        margin-bottom: get-vw-mobile(40px);;
        margin-top:get-vw-pc(30px);; 
    }
    p{
        font-size: get-vw-mobile(22px);;
    }
	li{
        font-size: get-vw-mobile(22px);;
    }
}





}

