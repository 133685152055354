@media screen and (max-width: 1000px){
footer{ 
        font-size: get-vw-pc(20px); 
        margin-top: get-vw-pc(50px); 
        max-width: get-vw-pc(1200px);        
    .content{     
      .columna{         
          margin-right: get-vw-pc(40px); ;        
          .items{
              margin-bottom: get-vw-pc(10px);
            b{              
                margin-bottom: get-vw-pc(20px); 
            }
        }
      }
        
    }
    
}
}