@media screen and (max-width: 600px){
footer{ 
        font-size: get-vw-mvl(20px); 
        margin-top: get-vw-mvl(50px); 
        max-width: 90%;               
    .content{    
         display: inline-block;
          width: 100%;
          text-align: center;
          margin-bottom: get-vw-mvl(30px);
      .columna{   
          display: inline-block;
          width: 100%;
          text-align: center;
          line-height: get-vw-mvl(30px); ;   
          margin-right: get-vw-mvl(0px); ;  
          margin-bottom: get-vw-mvl(30px);
          .items{
               display: inline-block;
          width: 100%;
          text-align: center;
              margin-bottom: get-vw-mvl(10px);
            b{        
                text-align: center;
                float:none;
                margin-bottom: get-vw-mvl(20px); 
            }
        }
      }
        
    }
    
}
}