@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin line-height($sizeValue: 1.6) {
  line-height: ($sizeValue * 10) + px;
  line-height: $sizeValue + rem;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@function get-vw($target) {
 // $vw-context: (1920 * 0.01) * 1px;
 // @return ($target / $vw-context) * 1vw;
 @return $target;
}

@function get-vw-pc($target) {
 $vw-context: (1680 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
}

@function get-vh($target) {
  $vw-context: (768 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vh;
}


@function get-vw-mobile($target) {
 $vw-context: (900 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}

@function get-vw-mvl($target) {
 $vw-context: (600 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}

@function get-vw-portatil($target) {
  $vw-context: (1400 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}

@function get-vw-tablet($target) {
  $vw-context: (1400 * 0.01) * 1px;
  @return ($target / $vw-context) * 1vw;
  @return $target;
}


@mixin clip-path($clip) {
  -webkit-clip-path: $clip;
  -moz-clip-path: $clip;
  -ms-clip-path: $clip;
  clip-path: $clip;
}
