$negro:#666666;
$negro2:#111623;

$morado1:#B51B87;
$morado2:#740691;

$rojo:#B51B87;
$fill:#F4F4F4;
$blanco:#FFF;
$gris:#848484;
$grisCCC:#CCCCCC;
$grisoscuro:#525252;
$azul:#2C3D8F;
$grisclaro:#F8F8F8;
$verde:#7FC461;

/*vars*/
$bezier1: cubic-bezier(0.23, 1, 0.32, 1);
$bezier2:cubic-bezier(.19,1,.22,1); 
$gw-wraper: 1500px;

$max-txt-limit: 1280px;

@smartphones: ~"only screen and (max-width: 479px)";
@tablet: ~"only screen and (max-width: 768px)";